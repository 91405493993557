export default {
  methods: {
    formatCurrency(number, decimal = false) {
      if (number === "") return;
      if (number === undefined) return;
      if (number === null) return;

      let formatter = new Intl.NumberFormat("en-US", {
        style: decimal ? "decimal" : "currency",
        currency: "USD",
      });

      return formatter.format(number);
    },
  },
};
