<template>
  <v-col cols="12" md="4">
    <v-card outlined :loading="loading">
      <v-card-text class="text--primary">
        <h3 class="mb-6">
          Sales Totals by Range

          <!-- {{
            chartData.length
              ? "- $" + formatCurrency(chartData[3][1], true)
              : ""
          }} -->
        </h3>

        <column-chart
          thousands=","
          :download="`SAV Sales Totals ${currentYear}`"
          prefix="$"
          :data="chartData"
        ></column-chart>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import formatCurrency from "@/mixins/formatCurrency";
import moment from "moment";

export default {
  mixins: [formatCurrency],
  data: () => ({
    loading: true,
    chartData: [],
  }),
  computed: {
    currentYear() {
      return moment().format("YYYY");
    },
    items() {
      // non archived event reports
      if (this.$store.state.eventReports == undefined) return [];
      if (this.$store.state.eventReports == null) return [];
      if (!this.$store.state.eventReports.length) return [];

      // filter out archived
      let items = this.$store.state.eventReports.filter((i) => !i.archived);
      if (!items.length) return [];

      return items;
    },
  },
  methods: {
    async salesThisMonth() {
      return new Promise(async (resolve, reject) => {
        // create item for chart
        let item = {
          month: "This Mo",
          values: 0,
          count: 0,
        };

        let data = [`(${item.count}) ${item.month}`, item.values];

        if (!this.items.length) resolve(data);

        // create range
        const start = moment().startOf("month");
        const end = moment().endOf("month");

        // check if i.momentEventDate falls within range (inBetween)
        let array = [];
        this.items.forEach((i) => {
          let eventDate = moment(i.eventDate);
          let withinRange = moment(eventDate).isBetween(start, end);
          if (withinRange) array.push(i);
        });

        if (!array.length) resolve(data);

        // format chartData
        // map values to include only eventDateMonth and eventReportTotal
        array = array.map((i) => {
          return {
            eventDateMonth: moment(i.eventDate).format("MMM"),
            total: i.eventReportTotal,
          };
        });

        item.count = array.length;

        // sum values for item
        let sum = 0;
        array.forEach((i) => {
          sum = i.total + sum;
        });
        item.values = parseInt(sum);

        data = [`(${item.count}) ${item.month}`, item.values];

        resolve(data);
      });
    },
    async salesLastThreeMonths() {
      return new Promise(async (resolve, reject) => {
        // create item for chart
        let item = {
          month: "Last 3 Mo",
          values: 0,
          count: 0,
        };

        let data = [`(${item.count}) ${item.month}`, item.values];

        if (!this.items.length) resolve(data);

        // create range
        const start = moment()
          .subtract("2", "months")
          .startOf("month");
        const end = moment().endOf("month");

        // check if eventDate falls within range (inBetween)
        let array = [];
        this.items.forEach((i) => {
          let eventDate = moment(i.eventDate);
          let withinRange = moment(eventDate).isBetween(start, end);
          if (withinRange) array.push(i);
        });

        if (!array.length) resolve(data);

        // format chartData
        // map values to include only eventDateMonth and eventReportTotal
        array = array.map((i) => {
          return {
            eventDateMonth: moment(i.eventDate).format("MMM"),
            total: i.eventReportTotal,
          };
        });

        item.count = array.length;

        // sum values for item
        let sum = 0;
        array.forEach((i) => {
          sum = i.total + sum;
        });
        item.values = parseInt(sum);

        data = [`(${item.count}) ${item.month}`, item.values];

        resolve(data);
      });
    },
    async salesLastSixMonths() {
      return new Promise(async (resolve, reject) => {
        // create item for chart
        let item = {
          month: "Last 6 Mo",
          values: 0,
          count: 0,
        };

        let data = [`(${item.count}) ${item.month}`, item.values];

        if (!this.items.length) resolve(data);

        // create range
        const start = moment()
          .subtract("5", "months")
          .startOf("month");
        const end = moment().endOf("month");

        // check if eventDate falls within range (inBetween)
        let array = [];
        this.items.forEach((i) => {
          let eventDate = moment(i.eventDate);
          let withinRange = moment(eventDate).isBetween(start, end);
          if (withinRange) array.push(i);
        });

        if (!array.length) resolve(data);

        // format chartData
        // map values to include only eventDateMonth and eventReportTotal
        array = array.map((i) => {
          return {
            eventDateMonth: moment(i.eventDate).format("MMM"),
            total: i.eventReportTotal,
          };
        });

        item.count = array.length;

        // sum values for item
        let sum = 0;
        array.forEach((i) => {
          sum = i.total + sum;
        });
        item.values = parseInt(sum);

        data = [`(${item.count}) ${item.month}`, item.values];

        resolve(data);
      });
    },
    async salesYtd() {
      return new Promise(async (resolve, reject) => {
        // create item for chart
        let item = {
          month: "YTD",
          values: 0,
          count: 0,
        };

        let data = [`(${item.count}) ${item.month}`, item.values];

        if (!this.items.length) resolve(data);

        // create range
        const start = moment().startOf("year");
        const end = moment().endOf("year");

        // check if eventDate falls within range (inBetween)
        let array = [];
        this.items.forEach((i) => {
          let eventDate = moment(i.eventDate);
          let withinRange = moment(eventDate).isBetween(start, end);
          if (withinRange) array.push(i);
        });

        if (!array.length) resolve(data);
        // format chartData
        // map values to include only eventDateMonth and eventReportTotal
        array = array.map((i) => {
          return {
            eventDateMonth: moment(i.eventDate).format("MMM"),
            total: i.eventReportTotal,
          };
        });

        item.count = array.length;

        // sum values for item
        let sum = 0;
        array.forEach((i) => {
          sum = i.total + sum;
        });
        item.values = parseInt(sum);

        data = [`(${item.count}) ${item.month}`, item.values];

        resolve(data);
      });
    },
  },
  watch: {
    items: {
      immediate: true,
      async handler(val) {
        if (!val || val == null || val == undefined) return;
        if (!val.length) return;

        const salesThisMonth = await this.salesThisMonth();
        const salesLastThreeMonths = await this.salesLastThreeMonths();
        const salesLastSixMonths = await this.salesLastSixMonths();
        // const salesYtd = await this.salesYtd();

        const promises = [
          salesThisMonth,
          salesLastThreeMonths,
          salesLastSixMonths,
          // salesYtd,
        ];

        Promise.allSettled(promises).then((results) => {
          this.loading = false;
          results.forEach((r) => {
            this.chartData.push(r.value);
          });
        });
      },
    },
  },
};
</script>
