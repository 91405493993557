<template>
  <!-- upcoming events calendar -->
  <v-col cols="12">
    <!-- toolbar -->
    <v-toolbar dense flat dark color="primary" rounded class="mb-2">
      <v-toolbar-title v-html="title" />
      <v-spacer />
      <v-toolbar-title
        v-html="
          $refs.calendar
            ? $refs.calendar.title
            : formatDate(new Date(), 'MMMM YYYY')
        "
      />

      <v-spacer />
      <v-toolbar-items>
        <v-btn @click="focus = ''" text :loading="loading" :disabled="loading">
          Today
        </v-btn>
        <v-btn
          fab
          text
          small
          @click="$refs.calendar.prev()"
          :loading="loading"
          :disabled="loading"
        >
          <v-icon> mdi-chevron-left-circle-outline </v-icon>
        </v-btn>
        <v-btn
          fab
          text
          small
          @click="$refs.calendar.next()"
          :loading="loading"
          :disabled="loading"
        >
          <v-icon> mdi-chevron-right-circle-outline </v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <!-- calendar -->
    <v-calendar
      ref="calendar"
      type="month"
      :events="events"
      event-overlap-mode="stack"
      v-model="focus"
      color="primary"
      :event-more="false"
      @click:event="showEvent"
      style="height:550px"
      id="upcomingEventsCalendar"
    ></v-calendar>

    <v-menu
      v-model="selectedOpen"
      :close-on-content-click="false"
      :activator="selectedElement"
      offset-x
    >
      <v-card
        color="grey lighten-4"
        min-width="350px"
        max-width="500px"
        flat
        :loading="loading"
      >
        <v-toolbar color="primary" flat dense dark>
          <v-toolbar-title>{{ selectedEvent.name }}</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="selectedOpen = false">
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="text--primary body-1">
          <span class="d-block">Email: {{ selectedEvent.email }}</span>
          <span class="d-block">Username: {{ selectedEvent.username }}</span>
          <span class="d-block"
            >Organization: {{ selectedEvent.organization }}</span
          >
          <span class="d-block">Event Name: {{ selectedEvent.name }}</span>
          <span class="d-block"
            >Date: {{ formatDate(selectedEvent.start) }}</span
          >
          <span class="d-block"
            >WishList:
            {{
              selectedEvent.wishlist && selectedEvent.wishlist.length > 0
                ? selectedEvent.wishlist
                : "empty"
            }}
          </span>
        </v-card-text>
      </v-card>
    </v-menu>
  </v-col>
</template>

<script>
import { mainApp } from "@/firebase";
import formatDate from "@/mixins/formatDate";
import moment from "moment";
import NProgress from "nprogress";
export default {
  mixins: [formatDate],
  data: () => ({
    loading: false,
    title: "Upcoming Events",
    focus: "",
    selectedElement: "",
    selectedOpen: "",
    selectedEvent: "",
    attrs: {
      class: "mb-6",
      elevation: 1,
    },
  }),
  computed: {
    events() {
      if (
        this.$store.state.mainWebsiteUserAccounts == null ||
        !this.$store.state.mainWebsiteUserAccounts.length
      )
        return;

      let array = [];

      this.$store.state.mainWebsiteUserAccounts.forEach((i) => {
        let username = i.name;
        let organization = i.organization;
        let events = i.events;
        if (!events.length) return;
        let lastEvent = events.slice(-1)[0];
        if (!lastEvent || !lastEvent.eventDate) return;
        if (lastEvent.wishListItems == undefined) lastEvent.wishListItems = [];
        let item = {
          color: "",
          email: i.email,
          name: lastEvent.eventName,
          username: username,
          organization: organization,
          start: new Date(`${lastEvent.eventDate}T00:00:00`),
          wishlist: lastEvent.wishListItems,
        };

        // checking if event is before today
        // if before today => color set to grey
        let today = moment().startOf("day");
        let eventDate = moment(lastEvent.eventDate);
        if (eventDate.isBefore(today)) item.color = "grey";

        array.push(item);
      });

      return array;
    },
  },
  methods: {
    async getMainWebsiteUserAccounts() {
      this.loading = true;
      NProgress.start();
      this.$store.state.mainWebsiteUserAccounts = [];
      mainApp
        .firestore()
        .collection("users")
        .get()
        .then((snap) => {
          snap.docs.forEach((i) =>
            this.$store.state.mainWebsiteUserAccounts.push(i.data())
          );
        });
      this.loading = false;
      NProgress.done();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
  },
  watch: {
    "$store.state.user": {
      immediate: true,
      async handler(user) {
        if (!user || user == null || user == undefined) return;
        if (user) return await this.getMainWebsiteUserAccounts();
      },
    },
  },
};
</script>

<style>
#upcomingEventsCalendar .v-calendar-weekly__day-label button {
  height: 25px;
  width: 25px;
  margin-bottom: 5px;
  font-size: 11px;
}
</style>
