<template>
  <!-- last ac campaign sent -->
  <v-col cols="12" md="4" class="d-flex">
    <v-card outlined>
      <v-card-text class="text--primary">
        <!-- <p class="text-center"> -->
        <div class="d-flex">
          <v-icon color="primary" class="display-1">mdi-currency-usd</v-icon>
          <!-- </p> -->
          <h3 class="title mt-1">Top 10 Selling Packages</h3>
        </div>
        <v-row>
          <!-- traveler -->
          <v-col cols="6"></v-col>
          <v-col cols="6" class="mt-1">
            <v-select
              label="Packages Filter"
              v-model="range"
              outlined
              dense
              :items="[
                'OverAll',
                'Last 1 month',
                'Last 3 month',
                'Last 6 month',
                'Custom date range',
              ]"
            ></v-select>
            <p v-if="dateRange.length !== 0" class="mt-n5">
              {{
                `${formatDate(this.sortDate(dateRange)[0])} - ${
                  formatDate(this.sortDate(dateRange)[1])
                }`
              }}
            </p></v-col
          ></v-row
        >

        <div class="body-2 ">
          <div v-for="(item, index) in top10Package" :key="index">
            {{ item.packageCode ? item.packageCode.toUpperCase() : "" }} -
            {{ item.count }}
          </div>
          <div v-if="data.length == 0">
            No Data Available
          </div>
        </div>
        <!-- <v-data-table
          :headers="headers"
          :items="top10Package"
          :items-per-page="5"
          class="elevation-1"
        ></v-data-table> -->
      </v-card-text>
    </v-card>
    <!-- Date Range picker pop up -->
    <v-dialog
      v-model="dialog"
      width="30%"
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card tile>
        <!-- dialog toolbar -->
        <v-toolbar
          dark
          color="primary"
          dense
          flat
          style="position:sticky;top:0;width:100%;z-index:500"
        >
          <v-toolbar-title>
            <strong>Select Dates</strong>
            <span class="ml-6"> </span>
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn depressed color="primary" dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-date-picker
          v-model="dateRange"
          range
          full-width
          dense
          class="border"
          style="min-height: 450px"
        ></v-date-picker></v-card
    ></v-dialog>
  </v-col>
</template>

<script>
import moment from "moment";
export default {
  data: () => ({
    dialog: false,
    range: "OverAll",
    dateRange: [],
    data: [],
    AllData: "",
    headers: [
      {
        text: "Package Code",
        align: "start",
        sortable: false,
        value: "packageCode",
      },
      { text: "Selling", sortable: false, value: "count" },
    ],
  }),

  mounted() {
    // Compute data before rendering and set to data state
    this.data = this.overAll;
  },

  watch: {
    AllData(newQuestion, oldQuestion) {
      if (range == "OverAll") {
        this.data = this.overAll;
      }
    },
    range(newQuestion, oldQuestion) {
      if ("Custom date range" == newQuestion) {
        this.dialog = true;
      }
      if ("OverAll" == newQuestion) {
        this.dateRange = [];
        this.data = this.overAll;
      }
      if ("Last 1 month" == newQuestion) {
        this.dateRange = [];
        this.data = this.filterData(this.findDate(1));
      }
      if ("Last 3 month" == newQuestion) {
        this.dateRange = [];
        this.data = this.filterData(this.findDate(3));
      }
      if ("Last 6 month" == newQuestion) {
        this.dateRange = [];
        this.data = this.filterData(this.findDate(6));
      }
    },
    dateRange(newQuestion, oldQuestion) {
      if (2 == this.dateRange.length) {
        this.dialog = false;
        // let sortDate =
        //   newQuestion[0] < newQuestion[1]
        //     ? newQuestion
        //     : [newQuestion[1], newQuestion[0]];
        this.data = this.filterData(this.sortDate(newQuestion));
      }
    },
  },
  methods: {
    formatDate(date){
      return moment(date).format("MM-DD-YYYY")
    },
    sortDate(newQuestion) {
      return newQuestion[0] < newQuestion[1]
        ? newQuestion
        : [newQuestion[1], newQuestion[0]];
    },
    findDate(month) {
      const toDate = moment().format("YYYY-MM-DD");
      const fromDate = moment()
        .subtract(month, "months")
        .format("YYYY-MM-DD");
      return [fromDate, toDate];
      // return (
      //   data.eventDate >=
      //     moment()
      //       .subtract(23, "months")
      //       .format("YYYY-MM") && data.eventDate <= this.currentMonths
      // );
    },
    filterData(date) {
      if (!this.$store.state.eventReports) return;
      let array = [];
      let items = this.$store.state.eventReports.filter((i) => !i.archived);
      items.forEach((i) => { 
        let winners = i.winners;
        if (!winners && winners.length) return;
        winners.forEach((w) => {
          if (!w.packageCode) return;
          if (i.datePaid >= date[0] && i.datePaid <= date[1])
            array.push(w.packageCode.trim().toLowerCase());
        });
      });
      let counts = array.reduce(
        (cnt, cur) => ((cnt[cur] = cnt[cur] + 1 || 1), cnt),
        {}
      );

      let output = [];

      for (var key of Object.keys(counts)) {
        let item = {
          packageCode: key,
          count: counts[key],
        };
        output.push(item);
      }

      output = output.sort((a, b) => b.count - a.count);
      output = output.slice(0, 10);
      if (output.length) return output;
      return [];
    },
  },
  computed: {
    top10Package() {
      if (this.range == "OverAll")
        this.data =
          this.$store.state.eventReports.length == 0 ? [] : this.overAll;

      return this.data;
    },
    currentDate() {
      return `${moment().format("YYYY-MM-DD")}`;
    },
    items() {},

    overAll() {
      if (!this.$store.state.eventReports) return;
      let array = [];
      let items = this.$store.state.eventReports.filter((i) => !i.archived);
      items.forEach((i) => {
        let winners = i.winners;
        if (!winners && winners.length) return;
        winners.forEach((w) => {
          if (!w.packageCode) return;
          array.push(w.packageCode.trim().toLowerCase());
        });
      });

      let counts = array.reduce(
        (cnt, cur) => ((cnt[cur] = cnt[cur] + 1 || 1), cnt),
        {}
      );

      let output = [];

      for (var key of Object.keys(counts)) {
        let item = {
          packageCode: key,
          count: counts[key],
        };
        output.push(item);
      }

      output = output.sort((a, b) => b.count - a.count);
      output = output.slice(0, 10);

      if (output.length && this.range == "OverAll") this.data = output;
      if (output.length) return output;
      return [];
    },
  },
};
</script>
