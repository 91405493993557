<template>
  <!-- auth dialog -->
  <v-dialog
    v-model="$store.state.authDialog"
    width="500"
    persistent
    :overlay-opacity="1"
  >
    <v-card outlined :loading="$store.state.loading">
      <v-card-text
        class="
              mt-6
              text--darken-1
              headline
              font-weight-medium
              text--primary
            "
      >
        Administrator Access Required
      </v-card-text>
      <v-card-text class="text--primary body-1">
        <p class="mb-7">
          Please sign in with administrator credentials to continue
        </p>
        <v-form ref="form">
          <v-text-field
            :loading="$store.state.loading"
            :disabled="$store.state.loading"
            label="Email *"
            v-model.trim="email"
            :rules="[textRules.required, emailRules.valid]"
            @keydown.enter="signIn"
            autofocus
          ></v-text-field>
          <v-text-field
            :loading="$store.state.loading"
            :disabled="$store.state.loading"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[textRules.required]"
            :type="showPassword ? 'text' : 'password'"
            label="Password *"
            class="input-group--focused"
            @click:append="showPassword = !showPassword"
            v-model="password"
            @keydown.enter="signIn"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          depressed
          @click="signIn"
          :loading="$store.state.loading"
          :disabled="$store.state.loading"
          >Sign In</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import signIn from "@/mixins/signIn";
import formRules from "@/mixins/formRules";
export default {
  mixins: [signIn, formRules],
};
</script>
