<template>
  <v-col cols="12" md="12">
    <v-card outlined>
      <v-card-text class="text--primary">
        <p class="text-center">
          <v-icon color="primary" class="display-1">mdi-currency-usd</v-icon>
        </p>
        <h3 class="title text-center mb-4">
          Reports Profitable Packages
        </h3>
        <v-row>
          <!-- Filter -->
          <v-col cols="8"></v-col>
          <v-col cols="4" class="mt-1">
            <v-select
              label="Packages Filter"
              v-model="range"
              outlined
              dense
              :items="[
                'Last 24 month',
                'Last 1 month',
                'Last 3 month',
                'Last 6 month',
                'Custom date range',
              ]"
            ></v-select>
            <p v-if="dateRange.length !== 0" class="mt-n5">
              {{
                `${formatDate(this.sortDate(dateRange)[0])} - ${
                  formatDate(this.sortDate(dateRange)[1])
                }`
              }}
            </p></v-col
          ></v-row
        >
        <v-data-table
          :headers="headers"
          :items="topProfitablePackage"
          :items-per-page="5"
          class="elevation-1"
        >
        <template v-slot:item.checkInDate="{ item }">
      {{ formatDate(item.checkInDate) }}
    </template>
    <template v-slot:item.netProfit="{ item }">
      ${{ item.netProfit.toFixed(2) }}
    </template>
    <template v-slot:item.profit="{ item }">
      {{ item.profit.toFixed(2) }}
    </template>
      </v-data-table>
      <!-- Date Range picker pop up -->
    <v-dialog
      v-model="dialog"
      width="30%"
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card tile>
        <!-- dialog toolbar -->
        <v-toolbar
          dark
          color="primary"
          dense
          flat
          style="position:sticky;top:0;width:100%;z-index:500"
        >
          <v-toolbar-title>
            <strong>Select Dates</strong>
            <span class="ml-6"> </span>
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn depressed color="primary" dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-date-picker
          v-model="dateRange"
          range
          full-width
          dense
          class="border"
          style="min-height: 450px"
        ></v-date-picker></v-card
    ></v-dialog> </v-card-text></v-card
  ></v-col>
</template>

<script>
import moment from 'moment';
import { portalsApp } from '../firebase';

const bookingsConfirmations =  portalsApp
  .firestore()
  .collection("bookingsConfirmations")
export default {
  async mounted() {
    await this.$store.dispatch("setLoading", true);
    await this.$bind(
      "bookingsConfirmations",
      bookingsConfirmations
    );
    await this.$store.dispatch("setLoading", false);
  },
  data: () => ({
    bookingsConfirmations:[],
    dialog: false,
    range: "Last 24 month",
    dateRange: [],
    data:[],
    headers: [
      {
        text: "Confirmation No.",
        align: "start",
        sortable: false,
        value: "confirmationNumber",
      },
      { text: "Package ID", sortable: false, value: "packageCode" },
      { text: "Hotel Name", sortable: false, value: "resortName" },
      { text: "Check-In Day", sortable: false, value: "checkInDate" },
      { text: "Net Profit",sortable: false, value: "netProfit"},
      { text: "% Profit",sortable: false, value: "profit"},
    ],
  }),
  watch: {
    AllData(newQuestion, oldQuestion) {
      if (range == "Last 24 month") {
        this.data = this.last24Months;
      }
    },
    range(newQuestion, oldQuestion) {
      if ("Custom date range" == newQuestion) {
        this.dialog = true;
      }
      if ("Last 24 month" == newQuestion) {
        this.dateRange = [];
        this.data = this.last24Months;
      }
      if ("Last 1 month" == newQuestion) {
        this.dateRange = [];
        this.data = this.filterData(this.findDate(1));
      }
      if ("Last 3 month" == newQuestion) {
        this.dateRange = [];
        this.data = this.filterData(this.findDate(3));
      }
      if ("Last 6 month" == newQuestion) {
        this.dateRange = [];
        this.data = this.filterData(this.findDate(6));
      }
    },
    dateRange(newQuestion, oldQuestion) {
      if (2 == this.dateRange.length) {
        this.dialog = false;
        this.data = this.filterData(this.sortDate(newQuestion));
      }
    },
  },
  computed:{
    
    items(){
      return  this.bookingsConfirmations.filter((i) => !i.archived).map(el=> {
        return {
          ...el,
          profit:  el.netProfit*100 / el.totalCost
        }
      })
    },
    topProfitablePackage() {
      if (this.range == "Last 24 month")
        this.data = this.last24Months

      return this.data;
    },
    
    last24Months() {
      return this.items.filter((data) => {
        return (
          data.dateConfirmed >=
            moment()
              .subtract(24, "months")
              .format("YYYY-MM") && data.dateConfirmed <= moment().format("YYYY-MM")
        );
      }).sort((a, b) => a.netProfit - b.netProfit).reverse()
    },
  },
  methods: {
    formatDateMap(array){
      return array.map(el=> {
        return {
          ...el,
          checkInDate: this.formatDate(el.checkInDate)
        }
      })
    },
    formatDate(date){
      return moment(date).format("MM-DD-YYYY")
    },
    filterData(date){
      return this.items.filter((data) => {
        return (
          data.dateConfirmed >=
          date[0]  && data.dateConfirmed <= date[1]
        );
      }).sort((a, b) => a.netProfit - b.netProfit).reverse()
    },
    findDate(month) {
      const toDate = moment().format("YYYY-MM");
      const fromDate = moment()
        .subtract(month, "months")
        .format("YYYY-MM");
      return [fromDate, toDate];
    },
    sortDate(newQuestion) {
      return newQuestion[0] < newQuestion[1]
        ? newQuestion
        : [newQuestion[1], newQuestion[0]];
    },
  },
}
</script>
