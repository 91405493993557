var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',{staticClass:"text--primary"},[_c('p',{staticClass:"text-center"},[_c('v-icon',{staticClass:"display-1",attrs:{"color":"primary"}},[_vm._v("mdi-currency-usd")])],1),_c('h3',{staticClass:"title text-center mb-4"},[_vm._v(" Reports Profitable Packages ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"8"}}),_c('v-col',{staticClass:"mt-1",attrs:{"cols":"4"}},[_c('v-select',{attrs:{"label":"Packages Filter","outlined":"","dense":"","items":[
              'Last 24 month',
              'Last 1 month',
              'Last 3 month',
              'Last 6 month',
              'Custom date range' ]},model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}}),(_vm.dateRange.length !== 0)?_c('p',{staticClass:"mt-n5"},[_vm._v(" "+_vm._s(((_vm.formatDate(this.sortDate(_vm.dateRange)[0])) + " - " + (_vm.formatDate(this.sortDate(_vm.dateRange)[1]))))+" ")]):_vm._e()],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.topProfitablePackage,"items-per-page":5},scopedSlots:_vm._u([{key:"item.checkInDate",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.checkInDate))+" ")]}},{key:"item.netProfit",fn:function(ref){
            var item = ref.item;
return [_vm._v(" $"+_vm._s(item.netProfit.toFixed(2))+" ")]}},{key:"item.profit",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.profit.toFixed(2))+" ")]}}])}),_c('v-dialog',{attrs:{"width":"30%","persistent":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-toolbar',{staticStyle:{"position":"sticky","top":"0","width":"100%","z-index":"500"},attrs:{"dark":"","color":"primary","dense":"","flat":""}},[_c('v-toolbar-title',[_c('strong',[_vm._v("Select Dates")]),_c('span',{staticClass:"ml-6"})]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"depressed":"","color":"primary","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-date-picker',{staticClass:"border",staticStyle:{"min-height":"450px"},attrs:{"range":"","full-width":"","dense":""},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }