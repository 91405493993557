<template>
  <!-- confirmations and upcoming travel calendar -->
  <v-col cols="12" style="margin-bottom: 100px">
    <div class="d-flex align-center">
      <h3 v-if="calendarTitle">
        Confirmed Travel Bookings - {{ calendarTitle }}
      </h3>
      <div class="ml-6">
        <v-btn
          outlined
          class="mr-4"
          @click="setToday"
          :loading="loading"
          :disabled="loading"
        >
          Today
        </v-btn>
        <v-btn
          fab
          class="ma-2"
          color="primary"
          small
          depressed
          @click="prev"
          :loading="loading"
          :disabled="loading"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn
          fab
          class="ma-2"
          color="primary"
          small
          depressed
          @click="next"
          :loading="loading"
          :disabled="loading"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </div>
    <v-calendar
      ref="calendar"
      type="month"
      :events="bookingsConfirmations"
      event-overlap-mode="stack"
      v-model="calendarFocus"
      color="primary"
      :event-more="false"
      @click:event="viewConfirmation"
    ></v-calendar>
  </v-col>
</template>

<script>
import { portalsApp } from "@/firebase";
export default {
  data: () => ({
    loading: false,
    bookingsConfirmations: [],
    calendarColors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    calendarTitle: "",
    calendarFocus: "",
  }),
  mounted() {
    this.getBookingsConfirmations();
  },
  methods: {
    viewConfirmation(e) {
      if (!e) return;
      this.$router.push(`/bookings/${e.event.id}`);
    },
    setToday() {
      this.calendarFocus = "";
      this.$nextTick(() => {
        this.calendarTitle = this.$refs.calendar.title;
      });
    },
    next() {
      this.$refs.calendar.next();
      this.$nextTick(() => {
        this.calendarTitle = this.$refs.calendar.title;
      });
    },
    prev() {
      this.$refs.calendar.prev();
      this.$nextTick(() => {
        this.calendarTitle = this.$refs.calendar.title;
      });
    },
    async getBookingsConfirmations() {
      this.loading = true;
      portalsApp
        .firestore()
        .collection("bookingsConfirmations")
        .where("isConfirmed", "==", true)
        .get()
        .then((snap) => {
          snap.docs.forEach((doc) => {
            let item = {
              name:
                doc.data().firstName +
                " " +
                doc.data().lastName +
                " - " +
                doc.data().resortName,
              start: new Date(`${doc.data().checkInDate}T00:00:00`),
              end: new Date(`${doc.data().checkOutDate}T00:00:00`),
              id: doc.data().id,
              color: this.calendarColors[
                Math.floor(Math.random() * this.calendarColors.length)
              ],
            };
            this.bookingsConfirmations.push(item);
          });
        });
      this.loading = false;
      this.calendarTitle = this.$refs.calendar.title;
    },
  },
};
</script>
