<template>
  <!-- active campaign subscribers -->
  <v-col cols="12" sm="6" md="4" class="d-flex">
    <v-card outlined :loading="loading">
      <v-card-text class="text--primary text-center">
        <p>
          <v-icon color="primary" class="display-1">mdi-account-outline</v-icon>
        </p>
        <h3 class="title">Active Campaign Subscribers</h3>
        <p class="title mt-3">
          {{ $store.state.acSubscriberCount }}
        </p>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import axios from "axios";
import NProgress from "nprogress";
export default {
  data: () => ({
    loading: false,
  }),
  methods: {
    async getActiveCampaignSubscriberTotal() {
      if (this.$store.state.acSubscriberCount != null) return;

      return new Promise(async (resolve, reject) => {
        this.loading = true;
        NProgress.start();

        try {
          let body = { path: "contacts" };

          let request = await axios.post("/api/get-active-campaign-data", body);

          if (request.data && request.data.meta.total)
            resolve(
              (this.$store.state.acSubscriberCount = request.data.meta.total)
            );

          this.loading = false;
          NProgress.done();
        } catch (error) {
          console.log(error);
          this.$toast.error("Error: getActiveCampaignSubscriberTotal()");
          this.loading = false;
          NProgress.done();
          reject(error);
        }
      });
    },
  },
  watch: {
    "$store.state.user": {
      immediate: true,
      async handler(user) {
        if (!user || user == null || user == undefined) return;
        if (user) return await this.getActiveCampaignSubscriberTotal();
      },
    },
  },
};
</script>
