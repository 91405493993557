import { portalsApp } from "@/firebase";
import signOut from "@/mixins/signOut";
export default {
  mixins: [signOut],
  data: () => ({
    email: "",
    password: "",
    showPassword: false,
  }),
  methods: {
    signIn() {
      // if form not valid
      if (!this.$refs.form.validate()) {
        this.$store.state.loading = false;
        this.$toast.error("Please check form for errors");
        return;
      }

      // form valid
      this.$store.state.loading = true;

      // signIn
      portalsApp
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then((user) => {
          this.$store.state.user = user.user;
          this.$store.state.authDialog = false;
          this.$store.state.loading = false;
          this.$toast.success("Admin Signed In");
        })
        .catch((err) => {
          this.$store.state.loading = false;
          this.$toast.error(err.message);
        });
    },
  },
};
