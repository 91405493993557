import { portalsApp } from "@/firebase";
import signOut from "@/mixins/signOut";

export default {
  beforeMount() {
    portalsApp.auth().onAuthStateChanged((user) => {
      console.log("authstate", user);
      // invalid user
      if (user == null || user == undefined)
        return (this.$store.state.authDialog = true);

      // user not admin

      if (user && user.uid != "Uds7cHTgXMZC8pxXEifBq7E5UtW2"){
        return portalsApp.auth().signOut()
      .then(() => {
          this.$store.state.authDialog = true;
          this.$store.state.loading = false;
          this.$store.state.user = false;
          this.$toast.error("You are not admin");
        })
        .catch((err) => {
          console.log(err);
        });
      }
      // user valid => set user in store
      return (this.$store.state.user = user);
    });
  },
};
