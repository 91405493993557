<template>
  <v-col cols="12" md="8">
    <v-card outlined :loading="loading">
      <v-card-text class="text--primary">
        <h3 class="mb-6">
          Sales By Month / Event Reports Submitted Last 24 Months
        </h3>
        <column-chart
          :data="salesByLastTwos"
          thousands=","
          :download="`SAV Sales By Month ${currentYear}`"
          prefix="$"
        ></column-chart>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import moment from "moment";
export default {
  data: () => ({
    loading: true,
  }),
  methods: {
    salesByMonth(year) {
      let items = this.last24Months.filter(
        (item) => new Date(item.eventDate).getFullYear() === year
      );

      //  if !items
      if (!items.length) {
        // this.loading = false;
        return [];
      }

      // reduce values down
      items = items.map((i) => {
        return {
          eventDateMonth: moment(i.eventDate).format("MMM"),
          total: parseInt(i.eventReportTotal),
          eventReportNumber: i.eventReportNumber,
        };
      });

      // get month name by number
      // let currentMonthNumber = parseInt(moment().format("M"));

      // create items for each month
      let months = [
        { month: "Jan", values: [], count: 0 },
        { month: "Feb", values: [], count: 0 },
        { month: "Mar", values: [], count: 0 },
        { month: "Apr", values: [], count: 0 },
        { month: "May", values: [], count: 0 },
        { month: "Jun", values: [], count: 0 },
        { month: "Jul", values: [], count: 0 },
        { month: "Aug", values: [], count: 0 },
        { month: "Sep", values: [], count: 0 },
        { month: "Oct", values: [], count: 0 },
        { month: "Nov", values: [], count: 0 },
        { month: "Dec", values: [], count: 0 },
      ];

      const startMonth = this.nextMonth;

      const startIndex = months.findIndex((x) => x.month === startMonth);

      const orderedMonths = [
        ...months.slice(startIndex),
        ...months.slice(0, startIndex),
      ];

      // for (let i = currentMonthNumber; i > 0; i++) {
      //   let item = {
      //     month: moment.monthsShort(i - 1),
      //     values: [],
      //     count: 0,
      //   };
      //   return console.log(item);
      //   months.push(item);
      // }

      // push values into each month array
      items.forEach((i) => {
        let month = orderedMonths.find((m) => m.month == i.eventDateMonth);
        month.values.push(i.total);
      });

      // values to return
      let values = [];

      // reduce values to total for that month
      orderedMonths.forEach((i) => {
        if (!i.values.length) return;
        i.count = i.values.length;
        let sum = i.values.reduce((a, b) => a + b, 0);
        values.push([i.month, sum]);
      });

      // `(${i.count}) ${i.month}`

      if (!values.length) return;
      this.loading = false;
      return values;
    },
  },
  computed: {
    items() {
      if (!this.$store.state.eventReports) return;
      return this.$store.state.eventReports.filter((i) => !i.archived);
    },
    nextMonth() {
      const nextMonth = moment().add(1, "months");
      return nextMonth.format("MMM");
    },
    last24Months() {
      return this.items.filter((data) => {
        return (
          data.eventDate >=
            moment()
              .subtract(23, "months")
              .format("YYYY-MM") && data.eventDate <= this.currentMonths
        );
      });
    },
    currentYear() {
      // console.log("moment", moment().format("LTS"));
      return moment().format("YYYY");
    },
    currentMonths() {
      return moment()
        .add(1, "months")
        .format("YYYY-MM");
    },
    nextMonth() {
      const nextMonth = moment().add(1, "months");
      return nextMonth.format("MMM");
    },
    lastYearMonths() {
      return `${moment().format("YYYY-MM") - 1}`;
    },
    lastYear() {
      return `${moment().format("YYYY") - 1}`;
    },
    salesByLastTwos() {
      const years = {
        current: Number(moment().format("YYYY")),
        previous: Number(
          moment()
            .subtract(1, "year")
            .format("YYYY")
        ),
        lastPrevious: Number(
          moment()
            .subtract(2, "year")
            .format("YYYY")
        ),
      };
      const first = this.salesByMonth(years.lastPrevious);
      const sec = this.salesByMonth(years.previous);
      const third = this.salesByMonth(years.current);

      const data = first.length
        ? [
            {
              name: years.lastPrevious,
              data: first,
            },
            {
              name: years.previous,
              data: sec,
            },
            {
              name: years.current,
              data: third,
            },
          ]
        : [
            {
              name: years.previous,
              data: sec,
            },
            {
              name: years.current,
              data: third,
            },
          ];
      // this.loading = false;
      return data;
    },
  },
};
</script>
<!-- :xtitle="`Last 24 Months Data`" -->
