// global helper functions and data
import Vue from 'vue';
import moment from 'moment';
Vue.mixin({
  data: () => ({
    emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  }),
  methods: {
    truncateText(text, limit) {
      if (text) {
        return text.slice(0, limit)
      }
    },
    formatDate(date) {
      if (date) {
        return moment(date).format("ddd MMM DD, YYYY");
      }
    },
    // formatDateWithTime(date) {
    //   if (date) {
    //     return moment(date).format("ddd MMM DD, YYYY @ hh:mm A");
    //   }
    // },
    formatPhone(phoneString) {
      if (phoneString) {
        let phone = phoneString;
        let cleaned = ("" + phone).replace(/\D/g, "");
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          phone = "(" + match[1] + ") " + match[2] + "-" + match[3];
          return phone;
        }
      }
    },
    limitNumberValues(e) {
      if (e) {
        let value = e.target.value;
        let charCode = e.charCode;
        let limit = 10;
        // max 10 characters
        if (value != undefined && value.toString().length >= limit) {
          e.preventDefault();
        }
        // not allowing characters 43(+), 45(-), 46(.), 69(E), 101(e)
        if (
          charCode == 43 ||
          charCode == 45 ||
          charCode == 46 ||
          charCode == 69 ||
          charCode == 101
        ) {
          e.preventDefault();
        }
      }
    },
    limitCharacterCount(e, limit) {
      let value = e.target.value;
      if (value != undefined && value.toString().length >= limit) {
        e.preventDefault();
      }
    },
    handleSuccess(successMessage) {
      this.$store.state.loading = false;
      this.$store.state.snackbar.display = true;
      this.$store.state.snackbar.color = "success";
      this.$store.state.snackbar.text = successMessage;
    },
    handleError(errorMessage) {
      this.$store.state.loading = false;
      this.$store.state.snackbar.display = true;
      this.$store.state.snackbar.color = "error";
      this.$store.state.snackbar.text = errorMessage;
    },
  }
})