import Vue from "vue";
import { vuexfireMutations, firestoreAction } from "vuexfire";
import Vuex from "vuex";

import { portalsApp } from "@/firebase";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: false,
    loading: false,
    snackbar: {
      display: false,
      color: "",
      text: "",
      timeout: 5000,
    },
    //
    authDialog: false,
    isSuperAdmin: false,
    eventReports: [],
    inventoryId: { id: "", editing: false },
    navDrawer: true,
    mainWebsiteUsersToday: null,
    mainWebsiteUsersLastSevenDays: null,
    mainWebsiteUsersLastThirtyDays: null,
    lastAcCampaignSent: null,
    acSubscriberCount: null,
    mainWebsiteUserAccounts: null,
  },

  mutations: {
    setLoading: (state, boolean) => (state.loading = boolean),
    setSnackbar: (state, values) => {
      state.snackbar.display = values.display;
      state.snackbar.color = values.color;
      state.snackbar.text = values.text;
      state.snackbar.timeout = values.timeout || 5000;
    },
    ...vuexfireMutations,
  },

  actions: {
    setLoading({ commit }, boolean) {
      commit("setLoading", boolean);
    },
    setSnackbar({ commit }, values) {
      commit("setSnackbar", values);
    },
    bindEventReports: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef(
        "eventReports",
        portalsApp.firestore().collection("eventReports")
      );
    }),
  },
});
