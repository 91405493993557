const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const numbersOnlyRegex = /^\d+$/;
const numbersAndDotsOnlyRegex = /^[.0-9]*$/;

export default {
  data: () => ({
    // started adding/replacing functions with methods on 5/29/22 to better see what's going on
    rules: {
      req: (v) => {
        if (v === undefined) return "Field Required";
        if (typeof v == "string") {
          if (!!!v) return "Field Required";
          let noWhiteSpace = v.replace(/\s+/g, "");
          if (noWhiteSpace.length == 0) return "Field Required";
        }
        return true;
      },
      email: (v) => {
        if (!emailRegex.test(v)) return "Please enter a valid email";
        return true;
      },
      numOnly(v) {
        if (v != "" && !numbersAndDotsOnlyRegex.test(v))
          return "Please Enter Numbers Only";
        return true;
      },
      tenChars(v) {
        if (v && v.length != 10)
          return "Please enter 10 numbers without spaces";
        return true;
      },
    },
    textRules: {
      required: (v) => !!v || "Field Required",
    },
    emailRules: {
      valid: (v) => emailRegex.test(v) || "Please enter a valid email",
      secondary: (v) =>
        !v || emailRegex.test(v) || "Please enter a valid email",
    },
    phoneRules: {
      tenNumbers: (v) => v.length == 10 || "Please enter 10 numbers",
      numbersOnly: (v) =>
        numbersOnlyRegex.test(v) || "Please enter numbers only without spaces",
      // not used
      maxTenNumbers: (v) =>
        (v || "").length <= 10 || "Please enter a maxiumum of 10 numbers",
    },
    checkboxRules: {
      required: (v) => !!v || "Field Required",
    },
    numberRules: {
      required: (v) => v != 0 || "Field Required",
    },
    selectRules: {
      boolean: (v) => !!v || v === false || "Field Required",
    },
    radioRules: {
      requiredBoolean: (v) => !!v || v === false || "Field Required",
    },
  }),
};
