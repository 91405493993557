import { portalsApp } from "@/firebase";

export default {
  methods: {
    signOut() {
      this.$store.state.loading = true;
      portalsApp
        .auth()
        .signOut()
        .then(() => {
          this.$store.state.authDialog = true;
          this.$store.state.loading = false;
          this.$store.state.user = false;
          this.$toast.success("Signed Out");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
