<template>
  <!-- sales this month -->
  <v-col cols="12" sm="6" md="4" class="d-flex">
    <v-card outlined :loading="loading">
      <v-card-text class="text--primary text-center">
        <p>
          <v-icon color="primary" class="display-1">mdi-currency-usd</v-icon>
        </p>
        <h3 class="title">Sales This Month</h3>
        <p class="title mt-3">
          {{ salesThisMonth ? `(${salesThisMonth.count})` : "" }}
          {{
            salesThisMonth
              ? `$${formatCurrency(salesThisMonth.amount, true)}`
              : ""
          }}
        </p>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import moment from "moment";
import NProgress from "nprogress";
import formatCurrency from "@/mixins/formatCurrency";
export default {
  mixins: [formatCurrency],
  data: () => ({
    loading: false,
  }),
  computed: {
    items() {
      if (!this.$store.state.eventReports) return;
      return this.$store.state.eventReports.filter((i) => !i.archived);
    },
    salesThisMonth() {
      if (
        !this.$store.state.user ||
        this.$store.state.user == null ||
        this.$store.state.user == undefined ||
        !this.items.length
      )
        return;

      this.loading = true;
      let currentYear = moment().format("YYYY");
      let currentMonth = moment().format("MM");
      let array = [];
      this.items.forEach((i) => {
        let year = i.eventDate.slice(0, 4);
        let month = i.eventDate.slice(5, 7);
        if (year === currentYear && month === currentMonth) {
          array.push(i);
        }
      });

      array = array.map((i) => i.eventReportTotal);
      let amount = array.reduce((a, b) => a + b, 0);
      this.loading = false;
      return { count: array.length, amount: amount };
    },
  },
};
</script>
