<template>
  <!-- achFeedback -->
  <v-col cols="12" sm="6" md="4" class="d-flex">
    <v-card outlined :loading="$store.state.loading">
      <v-card-text class="text--primary text-center">
        <p>
          <v-icon color="primary" class="display-1"
            >mdi-information-outline</v-icon
          >
        </p>
        <h3 class="title">ACH Feedback</h3>
        <p class="title mt-3">Total Submissions: {{ achFeedback.length }}</p>
        <p class="title mt-3">
          Interested: {{ achFeedback.filter((i) => i.value).length }} ({{
            parseInt(
              (100 * achFeedback.filter((i) => i.value).length) /
                achFeedback.length
            )
          }}%)
        </p>
        <p class="title mt-3">
          Not Interested: {{ achFeedback.filter((i) => !i.value).length }} ({{
            parseInt(
              (100 * achFeedback.filter((i) => !i.value).length) /
                achFeedback.length
            )
          }}%)
        </p>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import { portalsApp } from "@/firebase";

export default {
  firestore: {
    achFeedback: portalsApp
      .firestore()
      .collection("userFeedback")
      .doc("travelerPortal")
      .collection("achPayments"),
  },
  data: () => ({
    achFeedback: [],
  }),
};
</script>
