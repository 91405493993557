// receives date
// returns formatted value
// Thursday Jan 28, 2021

import moment from "moment";
moment.suppressDeprecationWarnings = true;
export default {
  methods: {
    formatDate(date, format = false) {
      if (!date) return;
      if (format) return moment(date).format(format);
      return moment(date).format("ddd MMM DD, YYYY");
    },
  },
};
